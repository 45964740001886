import SubMenu from "components/layout/SubMenu.js";
import React, { useState, useEffect } from "react";
import styles from "../TruckRental.module.css";
import {
  Avatar,
  Grid,
  InputAdornment,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import Loading from "components/loading/LoadingBackground";
import { Search, Add } from "@mui/icons-material";
import moment from "moment";
import AlertModal from "components/modal/AlertModal";
import { useNavigate } from "react-router-dom";
import helper from "utils/helper";
import TableActiveIcon from "components/tableActiveIcon";
import TableCustom from "components/table/TableCustom";
import { useCookies } from "react-cookie";
import ModalLessor from "./ModalLessor";
import EditIcon from "@mui/icons-material/Edit";
import SelectRowValue from "components/button/SelectRowValue";
import { getAllLessor } from "utils/api/truckRental/truckRental";

const TableLessor = () => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  //   const [openModalCreateCustomer, setOpenModalCreateCustomer] = useState(false);
  const [openModalLessor, setOpenModalLessor] = useState({
    open: false,
    type: "",
    idLessor: null,
  });
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [allLessor, setAllLessor] = useState([]);
  const dateTo = moment().format("YYYY-MM-DD 23:59:59");
  const dateFrom = moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00");
  const [selectItem, setSelectItem] = useState(null);
  const [filter, setFilter] = useState({
    sort_by: "created_at",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  console.log("selectItem", selectItem);
  console.log("allLessor", allLessor);
  const subMenu = [
    {
      label: "รายการน้ำมัน",
      path: "/trade",
    },
    // {
    //   label: "รายชื่อผู้ซื้อ",
    //   path: "/trade/customer",
    // },
    // {
    //   label: "รายชื่อผู้ขายน้ำมัน",
    //   path: "/trade/jobber",
    // },
  ];
  const breadcrumbs = [
    {
      label: "รายชื่อผู้ขายน้ำมัน",
      path: null,
    },
  ];

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      id: "lessor_code",
      label: "รหัสผู้ให้เช่า",
      align: "left",
    },
    {
      id: "lessor_name",
      label: "ชื่อบริษัท",
      align: "left",
    },
    {
      id: "tax_id",
      label: "เลขประจำตัวผู้เสียภาษี",
      align: "left",
    },
    // {
    //   id: "certificate_file",
    //   label: "ไฟล์หนังสือรับรอง",
    //   align: "center",
    //   disabledLink: true,
    //   format: (value) => {
    //     return value.certificate_file ? (
    //       <Typography
    //         onClick={() =>
    //           window.open(helper.addhtpps(value?.certificate_file))
    //         }
    //         sx={{ cursor: "pointer", textDecoration: "underline" }}
    //       >
    //         เปิดไฟล์
    //       </Typography>
    //     ) : null;
    //   },
    // },
    {
      id: "branch_code",
      label: "รหัสสาขา",
      align: "left",
    },
    {
      id: "address",
      label: "ที่อยู่",
      align: "left",
    },
    {
      label: "ประเทศ",
      align: "left",
      format: (value) => {
        return value.country.name;
      },
    },
    {
      label: "ภูมิภาค",
      align: "left",
      format: (value) => {
        return value.zone.name;
      },
    },
    {
      label: "จังหวัด",
      align: "left",
      format: (value) => {
        return value.province.name;
      },
    },
    {
      label: "อำเภอ",
      align: "left",
      format: (value) => {
        return value.district.name;
      },
    },
    {
      label: "ตำบล",
      align: "left",
      format: (value) => {
        return value.subdistrict.name;
      },
    },
    {
      label: "รหัสไปรษณีย์",
      align: "left",
      format: (value) => {
        return value.subdistrict.postal_code;
      },
    },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
    {
      id: "edit",
      label: "แก้ไข",
      align: "center",
      width: "10%",
      disabledLink: true,
      format: (value) => {
        return (
          <div style={{ position: "relative" }}>
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() =>
                value.id
                  ? setOpenModalLessor({
                      open: true,
                      type: "edit",
                      idLessor: value.id,
                    })
                  : null
              }
            >
              <EditIcon sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </div>
        );
      },
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"created_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "lessor_name", name: "ชื่อบริษัท" },
                { value: "tax_id", name: "เลขประจำตัวผู้เสียภาษี" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"lessor_name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "13px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                const filterSearch = { ...filter, page: 1 };
                getLessor(filterSearch);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <div>
              <SelectRowValue
                onSelect={(val) => {
                  setFilter({ ...filter, per_page: val });
                }}
              />
              {/* <FieldSelect
                name={"จำนวนแถว"}
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 20, name: "20" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                defaultValue={5}
                value={filter.per_page}
                onChange={(val) => {
                  setFilter({ ...filter, per_page: val });
                }}
                closePaddingBottom={true}
                fullWidth={true}
              /> */}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getLessor(filter);
  }, [filter.page, filter.per_page]);

  const getLessor = async (filter) => {
    setLoading(true);
    await getAllLessor(filter)
      .then((res) => {
        setAllLessor(res.data.results);
        setFilter({ ...filter, total: res.data.total });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate("/");
        }}
      />
      <Container>
        {/* <SubMenu
          menus={subMenu.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `รายชื่อผู้ขายน้ำมัน` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        /> */}
        <div style={{ paddingTop: "20px" }}></div>
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={allLessor}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            fontRow={"14px"}
            fontHead={"16px"}
            hrefDisabled={true}
          />
        </div>

        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() =>
            setOpenModalLessor({
              open: true,
              type: "create",
              idLessor: null,
            })
          }
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>
      <ModalLessor
        open={openModalLessor.open}
        type={openModalLessor.type}
        idLessor={openModalLessor.idLessor}
        handleClose={() => {
          setOpenModalLessor({ open: false, type: "", idLessor: null });
          getLessor(filter);
        }}
      />
    </React.Fragment>
  );
};

export default TableLessor;
